<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('게임접속'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'play',
      response: '',
      body: {
        vendorKey: { type: 'vendors', value: '', desc: this.$t('text.api.vendorKey_desc'), required: true },
        gameKey: { type: 'games', value: '', desc: this.$t('text.api.gameKey_desc'), required: true },
        lobbyKey: {type: 'text', value: 'top_games', desc: this.$t('text.api.lobbyKey_desc')},
        username: { type: 'text', value: 'user1', desc: this.$t('text.api.username_desc'), required: true, length: '1~21' },
        siteUsername: { type: 'text', value: 'user1', desc: this.$t('text.api.siteUsername_desc'), required: true, length: '1~21' },
        nickname: { type: 'text', value: '회원1', desc: this.$t('nickname'), length: '1~21' },
        groupKey: { type: 'text', value: 'shop1', desc: this.$t('text.api.groupKey_desc'), length: '1~32' },
        platform: { type: 'list', list: ['WEB', 'MOBILE'], value: 'WEB', desc: this.$t('text.api.platform_desc') },
        amount: { type: 'number', value: '0', desc: this.$t('text.api.amount_desc') },
        language: {type: 'list', list: ['ko','en'], value: 'ko', desc: '게임 언어'},
      }
    };
  },
  mounted() {
    const host = process.env.VUE_APP_API_SERVER_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }
     */
    this.apiUrl = host + 'play'

    // 응답값 설정
    this.response =
        '----------------------------------------------------------------------------\n' +
        '[gameKey] 배팅한도 안내 (KRW)\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        ' lobby100   : 1,000  ~  1,000,000\n' +
        ' lobby300   : 1,000  ~  3,000,000\n' +
        ' lobby500   : 5,000  ~  5,000,000\n' +
        ' lobby1000  : 5,000  ~  10,000,000\n' +
        ' lobby2000  : 10,000 ~  20,000,000\n' +
        ' lobby3000  : 10,000 ~  30,000,000\n' +
        ' lobby5000  : 25,000 ~  50,000,000\n' +
        ' lobby10000 : 50,000 ~  100,000,000\n' +
        '}\n\n'+
        '----------------------------------------------------------------------------\n' +
        '[응답값] 항목 내용을 참고하세요.\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        ' "code": 0, \t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        ' "url": "https://pl.vegaslounge.live/player/kgn21/launch?code=xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", // 게임 접속 URL\n' +
        ' "balance": 0, \t\t\t\t\t// 잔액\n' +
        ' "amount": 0 \t\t\t\t\t// 처리금액\n' +
        '}\n'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-primary">요청 URL</span> {{ apiUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_play_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="true" :require-ip-address="true" :require-request-key="true" :require-play="true" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
